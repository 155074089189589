import { CurrencyCode } from "@smartrr/shared/currencyCode";

import { getLocale } from "./getLocale";

//
export function toShopifyDecimal(value: number | string) {
  return value.toString();
}

export function removeTrailingZeros(value: number | string) {
  return value.toString().replace(/\.00/i, "");
}

/**
 * 1000 => 1,000
 * 10000000 => 10,000,000
 */
export function numberWithCommas(x: number | undefined): string {
  if (!x) {
    return "0";
  }
  // eslint-disable-next-line security/detect-unsafe-regex
  return x.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// export for use in places where this conversion needs to be done (i.e., the division) but do not need the currency symbol
export function convertNumberForFormatMoney(value: number | string, currencyCode: CurrencyCode) {
  const { currencyPrecision } = currencyPrecisionLookup[currencyCode];
  return +value / Math.pow(10, currencyPrecision);
}

export function formatMoney(value: number | string, currencyCode: CurrencyCode, nothingAfterDecimal = false) {
  // prices are stored in cents as we intend to support multi-currency at some point
  const convertedNumber = convertNumberForFormatMoney(value, currencyCode);

  return new Intl.NumberFormat(getLocale(), {
    style: "currency",
    currency: currencyCode,
  }).format(nothingAfterDecimal ? Math.round(convertedNumber) : convertedNumber);
}

export function formatMoneyWithoutPrecisionConversion(
  value: number | string,
  currencyCode: CurrencyCode,
  nothingAfterDecimal = false
) {
  const convertedNumber = Number(value);

  return new Intl.NumberFormat(getLocale(), {
    style: "currency",
    currency: currencyCode,
  }).format(nothingAfterDecimal ? Math.round(convertedNumber) : convertedNumber);
}

const nonNumericRegex = new RegExp("[^0-9-(-)-.]", "g");
const bracketedValuesRegex = /\((-*\d*[^)]?\d+)\)/g;
const bracketsWithoutValuesRegex = /\((.*)\)/;
const dashRegex = /-/g;

export function unformatMoney(value: number | string, currencyCode: CurrencyCode) {
  try {
    if (typeof value === "number") {
      return value;
    }

    if (typeof value !== "string" && typeof value !== "number") {
      throw new TypeError("unformatMoney: value must be a string or number");
    }

    if (value === "") {
      throw new Error("unformatMoney: value is empty string");
    }
    // Build regex to strip out everything except digits, decimal point and minus sign
    const unformattedValueString = value
      .replaceAll(nonNumericRegex, "") // strip out any cruft
      .replaceAll(bracketedValuesRegex, "-$1") // replace bracketed values with negatives
      .replace(bracketsWithoutValuesRegex, ""); // remove any brackets that do not have numeric value
    nonNumericRegex.lastIndex = 0;
    bracketedValuesRegex.lastIndex = 0;

    /**
     * Handling -ve number and bracket, eg.
     * (-100) = 100, -(100) = 100, --100 = 100
     */
    const negative = !!((unformattedValueString.match(dashRegex) || []).length % 2);
    dashRegex.lastIndex = 0;

    const absUnformatted = parseFloat(unformattedValueString.replaceAll(dashRegex, ""));
    dashRegex.lastIndex = 0;

    const unformatted = absUnformatted * (negative ? -1 : 1);

    const amount = isNaN(unformatted) ? 0 : unformatted;
    const currencyPrecision = currencyPrecisionLookup[currencyCode].currencyPrecision;
    return Math.round(amount * Math.pow(10, currencyPrecision));
  } catch (error) {
    throw new Error(`Could not unformat string ${value} and currency ${currencyCode}: ${error}`);
  }
}

export const currencyPrecisionLookup: {
  [key in CurrencyCode]: { currencyName: string; currencyPrecision: number };
} = {
  AED: { currencyName: "United Arab Emirates dirham", currencyPrecision: 2 },
  AFN: { currencyName: "Afghanistan afghani", currencyPrecision: 2 },
  AMD: { currencyName: "Armenian dram", currencyPrecision: 2 },
  ANG: { currencyName: "Netherlands Antillean guilder", currencyPrecision: 2 },
  AOA: { currencyName: "Angola kwanza", currencyPrecision: 2 },
  ARS: { currencyName: "Argentine peso", currencyPrecision: 2 },
  AUD: { currencyName: "Australian dollar", currencyPrecision: 2 },
  AWG: { currencyName: "Aruban florin", currencyPrecision: 2 },
  AZN: { currencyName: "Azerbaijani manat", currencyPrecision: 2 },
  BAM: {
    currencyName: "Bosnia-Herzegovina convertible mark",
    currencyPrecision: 2,
  },
  BBD: { currencyName: "Barbados dollar", currencyPrecision: 2 },
  BDT: { currencyName: "Bangladeshi taka", currencyPrecision: 2 },
  BGN: { currencyName: "Bulgarian lev", currencyPrecision: 2 },
  BHD: { currencyName: "Bahraini dinar", currencyPrecision: 3 },
  BIF: { currencyName: "Burundian franc", currencyPrecision: 0 },
  BMD: { currencyName: "Bermuda dollar", currencyPrecision: 2 },
  BND: { currencyName: "Brunei dollar", currencyPrecision: 2 },
  BOB: { currencyName: "Bolivian boliviano", currencyPrecision: 2 },
  BRL: { currencyName: "Brazilian real", currencyPrecision: 2 },
  BSD: { currencyName: "Bahamian dollar", currencyPrecision: 2 },
  BWP: { currencyName: "Botswana pula", currencyPrecision: 2 },
  BYR: { currencyName: "Belarussian ruble", currencyPrecision: 0 },
  BYN: { currencyName: "Belarussian ruble", currencyPrecision: 2 },
  BZD: { currencyName: "Belize dollar", currencyPrecision: 2 },
  CAD: { currencyName: "Canadian dollar", currencyPrecision: 2 },
  CDF: { currencyName: "Congolese franc", currencyPrecision: 2 },
  CHF: { currencyName: "Swiss franc", currencyPrecision: 2 },
  CLP: { currencyName: "Chilean peso", currencyPrecision: 0 },
  CNY: { currencyName: "Chinese yuan renminbi", currencyPrecision: 2 },
  COP: { currencyName: "Columbian peso", currencyPrecision: 2 },
  CRC: { currencyName: "Costa Rican colon", currencyPrecision: 2 },
  CVE: { currencyName: "Cape Verde escudo", currencyPrecision: 2 },
  CZK: { currencyName: "Czech koruna", currencyPrecision: 2 },
  DJF: { currencyName: "Djiboutian franc", currencyPrecision: 0 },
  DKK: { currencyName: "Danish krone", currencyPrecision: 2 },
  DOP: { currencyName: "Dominican peso", currencyPrecision: 2 },
  DZD: { currencyName: "Algerian dinar", currencyPrecision: 2 },
  EGP: { currencyName: "Egyptian pound", currencyPrecision: 2 },
  ERN: { currencyName: "Eritrean nakfa", currencyPrecision: 2 },
  ETB: { currencyName: "Ethiopian birr", currencyPrecision: 2 },
  EUR: { currencyName: "Euro", currencyPrecision: 2 },
  FJD: { currencyName: "Fiji dollar", currencyPrecision: 2 },
  FKP: { currencyName: "Falkland Islands pound", currencyPrecision: 2 },
  GBP: { currencyName: "British pound sterling", currencyPrecision: 2 },
  GEL: { currencyName: "Georgian lari", currencyPrecision: 2 },
  GHS: { currencyName: "Ghana cedi", currencyPrecision: 2 },
  GIP: { currencyName: "Gibraltar pound", currencyPrecision: 2 },
  GMD: { currencyName: "Gambian dalasi", currencyPrecision: 2 },
  GNF: { currencyName: "Guinean franc", currencyPrecision: 0 },
  GTQ: { currencyName: "Guatemalan quetzal", currencyPrecision: 2 },
  GYD: { currencyName: "Guyanese dollar", currencyPrecision: 2 },
  HKD: { currencyName: "Hong Kong dollar", currencyPrecision: 2 },
  HNL: { currencyName: "Hunduran Lempira", currencyPrecision: 2 },
  HRK: { currencyName: "Croatian kuna", currencyPrecision: 2 },
  HTG: { currencyName: "Haitian gourde", currencyPrecision: 2 },
  HUF: { currencyName: "Hungarian forint", currencyPrecision: 2 },
  IDR: { currencyName: "Indonesian rupiah", currencyPrecision: 2 },
  ILS: { currencyName: "Israeli sheqel", currencyPrecision: 2 },
  INR: { currencyName: "Indian rupee", currencyPrecision: 2 },
  IQD: { currencyName: "Iraqi dinar", currencyPrecision: 3 },
  ISK: { currencyName: "Icelandic krona", currencyPrecision: 2 },
  JMD: { currencyName: "Jamaican dollar", currencyPrecision: 2 },
  JOD: { currencyName: "Jordanian dinar", currencyPrecision: 3 },
  JPY: { currencyName: "Japanese yen", currencyPrecision: 0 },
  KES: { currencyName: "Kenyan shilling", currencyPrecision: 2 },
  KGS: { currencyName: "Kyrgyzstani som", currencyPrecision: 2 },
  KHR: { currencyName: "Cambodian riel", currencyPrecision: 2 },
  KMF: { currencyName: "Comoro franc", currencyPrecision: 0 },
  KRW: { currencyName: "South Korean won", currencyPrecision: 0 },
  KWD: { currencyName: "Kuwaiti dinar", currencyPrecision: 3 },
  KYD: { currencyName: "Cayman Islands dollar", currencyPrecision: 2 },
  KZT: { currencyName: "Kazakhstani tenge", currencyPrecision: 2 },
  LAK: { currencyName: "Lao kip", currencyPrecision: 2 },
  LBP: { currencyName: "Lebanese pound", currencyPrecision: 2 },
  LKR: { currencyName: "Sri Lanka rupee", currencyPrecision: 2 },
  LRD: { currencyName: "Liberian dollar", currencyPrecision: 2 },
  LSL: { currencyName: "Lesotho loti", currencyPrecision: 2 },
  LTL: { currencyName: "Lithuanian litas", currencyPrecision: 2 },
  LVL: { currencyName: "Latvian lats", currencyPrecision: 2 },
  MAD: { currencyName: "Moroccan dirham", currencyPrecision: 2 },
  MDL: { currencyName: "Moldovan leu", currencyPrecision: 2 },
  MGA: { currencyName: "Malagasy ariary", currencyPrecision: 0 },
  MKD: { currencyName: "Macedonian denar", currencyPrecision: 2 },
  MMK: { currencyName: "Myanmar kyat", currencyPrecision: 2 },
  MNT: { currencyName: "Mongolian tugrik", currencyPrecision: 2 },
  MOP: { currencyName: "Macanese pataca", currencyPrecision: 2 },
  MUR: { currencyName: "Mauritius rupee", currencyPrecision: 2 },
  MVR: { currencyName: "Maldivian rufiyaa", currencyPrecision: 2 },
  MWK: { currencyName: "Malawian kwacha", currencyPrecision: 2 },
  MXN: { currencyName: "Mexican peso", currencyPrecision: 2 },
  MYR: { currencyName: "Malaysian ringgit", currencyPrecision: 2 },
  MZN: { currencyName: "Mozambican metical", currencyPrecision: 2 },
  NAD: { currencyName: "Namibian dollar", currencyPrecision: 2 },
  NGN: { currencyName: "Nigerian naira", currencyPrecision: 2 },
  NIO: { currencyName: "Cordoba oro", currencyPrecision: 2 },
  NOK: { currencyName: "Norwegian krone", currencyPrecision: 2 },
  NPR: { currencyName: "Nepalese rupee", currencyPrecision: 2 },
  NZD: { currencyName: "New Zealand dollar", currencyPrecision: 2 },
  OMR: { currencyName: "Omani rial", currencyPrecision: 3 },
  PAB: { currencyName: "Panamanian balboa", currencyPrecision: 2 },
  PEN: { currencyName: "Peruvian nuevo sol", currencyPrecision: 2 },
  PGK: { currencyName: "Papua New Guinean kina", currencyPrecision: 2 },
  PHP: { currencyName: "Philippine peso", currencyPrecision: 2 },
  PKR: { currencyName: "Pakistan rupee", currencyPrecision: 2 },
  PLN: { currencyName: "Polish zloty", currencyPrecision: 2 },
  PYG: { currencyName: "Paraguayan guarani", currencyPrecision: 0 },
  QAR: { currencyName: "Qatari rial", currencyPrecision: 2 },
  RON: { currencyName: "Romanian leu", currencyPrecision: 2 },
  RSD: { currencyName: "Serbian dinar", currencyPrecision: 2 },
  RUB: { currencyName: "Russian ruble", currencyPrecision: 2 },
  RWF: { currencyName: "Rwanda franc", currencyPrecision: 0 },
  SAR: { currencyName: "Saudi Arabian riyal", currencyPrecision: 2 },
  SBD: { currencyName: "Solomon Islands dollar", currencyPrecision: 2 },
  SCR: { currencyName: "Seychelles rupee", currencyPrecision: 2 },
  SEK: { currencyName: "Swedish krona", currencyPrecision: 2 },
  SGD: { currencyName: "Singapore dollar", currencyPrecision: 2 },
  SHP: { currencyName: "Saint Helena pound", currencyPrecision: 2 },
  SLL: { currencyName: "Sierra Leonean leone", currencyPrecision: 2 },
  SOS: { currencyName: "Somali shilling", currencyPrecision: 2 },
  SRD: { currencyName: "Surinamese dollar", currencyPrecision: 2 },
  SSP: { currencyName: "South Sudanese pound", currencyPrecision: 2 },
  STD: { currencyName: "Sao Tome and Principe dobra", currencyPrecision: 2 },
  SYP: { currencyName: "Syrian pound", currencyPrecision: 2 },
  SZL: { currencyName: "Swaziland lilangeni", currencyPrecision: 2 },
  THB: { currencyName: "Thai baht", currencyPrecision: 2 },
  TJS: { currencyName: "Tajikistani somoni", currencyPrecision: 2 },
  TND: { currencyName: "Tunisian dinar", currencyPrecision: 3 },
  TOP: { currencyName: "Tongan pa'anga", currencyPrecision: 2 },
  TRY: { currencyName: "Turkish lira", currencyPrecision: 2 },
  TTD: { currencyName: "Trinidad and Tobago dollar", currencyPrecision: 2 },
  TWD: { currencyName: "Taiwan dollar", currencyPrecision: 2 },
  TZS: { currencyName: "Tanzanian shilling", currencyPrecision: 2 },
  UAH: { currencyName: "Ukrainian hryvnia", currencyPrecision: 2 },
  UGX: { currencyName: "Ugandan shilling", currencyPrecision: 2 },
  USD: { currencyName: "United States dollar", currencyPrecision: 2 },
  UYU: { currencyName: "Uruguayan peso", currencyPrecision: 2 },
  UZS: { currencyName: "Uzbekistan som", currencyPrecision: 2 },
  VEF: { currencyName: "Venezuelan bolivar fuerte", currencyPrecision: 2 },
  VND: { currencyName: "Vietnamese dong", currencyPrecision: 0 },
  VUV: { currencyName: "Vanuatu vatu", currencyPrecision: 0 },
  WST: { currencyName: "Samoan tala", currencyPrecision: 2 },
  XAF: { currencyName: "CFA franc BEAC", currencyPrecision: 0 },
  XCD: { currencyName: "East Caribbean dollar", currencyPrecision: 2 },
  XOF: { currencyName: "CFA Franc BCEAO", currencyPrecision: 0 },
  XPF: { currencyName: "CFP franc", currencyPrecision: 0 },
  YER: { currencyName: "Yemeni rial", currencyPrecision: 2 },
  ZAR: { currencyName: "South African rand", currencyPrecision: 2 },
  ZMW: { currencyName: "Zambian kwacha", currencyPrecision: 2 },

  ALL: { currencyName: "Albanian Lek", currencyPrecision: 2 },
  BTN: { currencyName: "Bhutan Ngultrum", currencyPrecision: 2 },
  IRR: { currencyName: "Iranian Rial", currencyPrecision: 2 },
  JEP: { currencyName: "Jersey Pounds", currencyPrecision: 2 },
  KID: { currencyName: "Kiribati dollar", currencyPrecision: 2 },
  LYD: { currencyName: "Libyan Dinar", currencyPrecision: 3 },
  MRU: { currencyName: "Mauritanian ouguiya", currencyPrecision: 2 },
  SDG: { currencyName: "Sudanese Pound", currencyPrecision: 2 },
  TMT: { currencyName: "Turkmenistan New Manat", currencyPrecision: 2 },
  VES: { currencyName: "Venezuela Sovereign Bolivar", currencyPrecision: 2 },
  XXX: { currencyName: "Unrecognized currency", currencyPrecision: 2 },
  STN: { currencyName: "Sao Tome And Principe Dobra", currencyPrecision: 2 },
  VED: { currencyName: "Venezuelan Bolivares (VED)", currencyPrecision: 2 },
};

export function getSymbolFromCurrency(currency: CurrencyCode): string {
  const currencySymbols: { [key in CurrencyCode]: string } = {
    AED: "د.إ",
    AFN: "؋",
    ALL: "L",
    AMD: "֏",
    ANG: "ƒ",
    AOA: "Kz",
    ARS: "$",
    AUD: "$",
    AWG: "ƒ",
    AZN: "₼",
    BAM: "KM",
    BBD: "$",
    BDT: "৳",
    BGN: "лв",
    BHD: ".د.ب",
    BIF: "FBu",
    BMD: "$",
    BND: "$",
    BOB: "$b",
    BRL: "R$",
    BSD: "$",
    BTN: "Nu.",
    BWP: "P",
    BYN: "Br",
    BYR: "Br",
    BZD: "BZ$",
    CAD: "$",
    CDF: "FC",
    CHF: "CHF",
    CLP: "$",
    CNY: "¥",
    COP: "$",
    CRC: "₡",
    CVE: "$",
    CZK: "Kč",
    DJF: "Fdj",
    DKK: "kr",
    DOP: "RD$",
    DZD: "دج",
    EGP: "£",
    ERN: "Nfk",
    ETB: "Br",
    EUR: "€",
    FJD: "$",
    FKP: "£",
    GBP: "£",
    GEL: "₾",
    GHS: "GH₵",
    GIP: "£",
    GMD: "D",
    GNF: "FG",
    GTQ: "Q",
    GYD: "$",
    HKD: "$",
    HNL: "L",
    HRK: "kn",
    HTG: "G",
    HUF: "Ft",
    IDR: "Rp",
    ILS: "₪",
    INR: "₹",
    IQD: "ع.د",
    IRR: "﷼",
    ISK: "kr",
    JEP: "£",
    JMD: "J$",
    JOD: "JD",
    JPY: "¥",
    KES: "KSh",
    KGS: "лв",
    KHR: "៛",
    KMF: "CF",
    KRW: "₩",
    KWD: "KD",
    KYD: "$",
    KZT: "₸",
    LAK: "₭",
    LBP: "£",
    LKR: "₨",
    LRD: "$",
    LSL: "M",
    LTL: "Lt",
    LVL: "Ls",
    LYD: "LD",
    MAD: "MAD",
    MDL: "lei",
    MGA: "Ar",
    MKD: "ден",
    MMK: "K",
    MNT: "₮",
    MOP: "MOP$",
    MRU: "UM",
    MUR: "₨",
    MVR: "Rf",
    MWK: "MK",
    MXN: "$",
    MYR: "RM",
    MZN: "MT",
    NAD: "$",
    NGN: "₦",
    NIO: "C$",
    NOK: "kr",
    NPR: "₨",
    NZD: "$",
    OMR: "﷼",
    PAB: "B/.",
    PEN: "S/.",
    PGK: "K",
    PHP: "₱",
    PKR: "₨",
    PLN: "zł",
    PYG: "Gs",
    QAR: "﷼",
    RON: "lei",
    RSD: "Дин.",
    RUB: "₽",
    RWF: "R₣",
    SAR: "﷼",
    SBD: "$",
    SCR: "₨",
    SDG: "ج.س.",
    SEK: "kr",
    SGD: "S$",
    SHP: "£",
    SLL: "Le",
    SOS: "S",
    SRD: "$",
    SSP: "£",
    STD: "Db",
    STN: "Db",
    SYP: "£",
    SZL: "E",
    THB: "฿",
    TJS: "SM",
    TMT: "T",
    TND: "د.ت",
    TOP: "T$",
    TRY: "₺",
    TTD: "TT$",
    TWD: "NT$",
    TZS: "TSh",
    UAH: "₴",
    UGX: "USh",
    USD: "$",
    UYU: "$U",
    UZS: "лв",
    VEF: "Bs",
    VES: "Bs.S",
    VND: "₫",
    VUV: "VT",
    WST: "WS$",
    XAF: "FCFA",
    XCD: "$",
    XOF: "CFA",
    XPF: "₣",
    YER: "﷼",
    ZAR: "R",
    ZMW: "ZK",
    KID: "undefined",
    VED: "undefined",
    XXX: "undefined",
  };

  if (currencySymbols[currency] && currencySymbols[currency] !== "undefined") {
    return currencySymbols[currency];
  }
  return currency;
}
